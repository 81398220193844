import React from "react"
import Link from "next/link"

const Nav = () => {
  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link href="/pricing">Pricing</Link>
        </li>
        <li>
          <Link href="/AuthPage">Sign In</Link>
        </li>
      </ul>
    </>
  )
}

export default Nav
